import React, { useEffect } from "react";
import { useState } from "react";
import LandingFooter from "../../../layout/LandingFooter/footer";

import styles from "./portfolio.module.css";

import TopPaddingSpacer from "../Global/TopPaddingSpacer";

const CompanyPage = ({ className, company, onClose }) => {
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    const start = window.pageYOffset;
    const end = 0;
    const change = end - start;
    const duration = 100; // Duration in milliseconds
    let startTime;

    function animateScroll(timestamp) {
      if (!startTime) startTime = timestamp;
      const timeElapsed = timestamp - startTime;
      const progress = Math.min(timeElapsed / duration, 1);

      window.scrollTo(0, start + change * progress);

      if (timeElapsed < duration) {
        requestAnimationFrame(animateScroll);
      }
    }

    requestAnimationFrame(animateScroll);
  }, []);

  function forceAlphaToOne(rgba) {
    const parts = rgba.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*[\d.]+)?\)/);
    if (parts) {
      return `rgba(${parts[1]}, ${parts[2]}, ${parts[3]}, 0.5)`;
    }
    return rgba;
  }

  return (
    <div
      className={`${styles.company_page} ${className}`}
      style={{
        backgroundColor: company.background_color,
      }}
    >
      <div
        className={styles.back_button}
        onClick={onClose}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <img
          src={
            isHovering
              ? "/Arrows/blackFullArrow.png"
              : "/Arrows/whiteFullArrow.png"
          }
          className={styles.back_arrow}
        />
      </div>
      <div className={styles.company_page_header}>
        <img className={styles.company_page_logo} src={company.logo} />
        <div className={styles.company_page_description_box}>
          <p className={styles.company_page_description_text}>
            {company.description}
          </p>
        </div>
      </div>

      <div
        className={styles.page_line_break}
        style={{
          backgroundColor: forceAlphaToOne(company.background_color),
        }}
      ></div>
      <div className={styles.company_info_section}>
        <div className={styles.company_point}>
          <p className={styles.point_title}>Company Name</p>
          <p className={styles.point_value}>{company.company_name}</p>
        </div>
        <div className={styles.company_point}>
          <p className={styles.point_title}>Sector</p>
          <p className={styles.point_value}>{company.sector}</p>
        </div>
        <div className={styles.company_point}>
          <p className={styles.point_title}>Year Founded</p>
          <p className={styles.point_value}>{company.year}</p>
        </div>
        <div className={styles.company_point}>
          <p className={styles.point_title}>Website</p>

          {/* <div className={styles.visit_site_button}>
            <p className={styles.point_value}>Visit Website</p>
            <img
              src="/Arrows/whiteFullArrowUpRight.png"
              className={styles.whiteFullArrowUpRight}
            />
          </div> */}
        </div>
      </div>
      <div
        className={styles.page_line_break}
        style={{
          backgroundColor: forceAlphaToOne(company.background_color),
        }}
      ></div>
      {/* <div className={styles.about_company_section}>
        <div className={styles.company_information}>
          <p className={styles.about_us_title}>About {company.company_name}</p>
          <p className={styles.company_information_text}>
            Have you ever saw something in a movie and wished you could will it
            in to existence. This is the goal of artemis, to bring the
            technology of science fiction in to the hands of the general
            consumer. Aiming to reinvent the idea of what people define as a
            start home, Artemis is invention.
          </p>
        </div>
      </div>
      <img
        className={styles.company_image}
        src="/CompanyPageImages/IMG_8500.JPG"
      ></img>
      <div
        className={styles.page_line_break}
        style={{
          backgroundColor: forceAlphaToOne(company.background_color),
        }}
      ></div>
      <div className={styles.career_section}>
        <p className={styles.about_us_title}>
          Careers at {company.company_name}
        </p>
        <p className={styles.company_information_text}>
          We're on the lookout for visionary talents who are passionate about
          pushing the boundaries of what's possible. At Artemis, you'll
          collaborate with a team of experts in AI, robotics, and IoT to develop
          solutions that make smart homes more intuitive, secure, and
          revolutionary. Whether you're an engineer, a designer, a developer, or
          a strategist, your work will contribute directly to creating
          technologies that enhance daily living in ways previously confined to
          the realm of science fiction.
        </p>
      </div>
      <div
        className={styles.page_line_break}
        style={{
          backgroundColor: forceAlphaToOne(company.background_color),
        }}
      ></div> */}
      {/* <LandingFooter /> */}
    </div>
  );
};

export default CompanyPage;

{
  /* <div className={styles.page_titles}>
          <p className={styles.page_title}>Our Mission</p>
          <p className={styles.page_description}>
            Our mission is to empower founders, fueling their journey from
            ideation to impact. At the core of our philosophy lies the
            celebration of both innovation and invention—two forces that drive
          </p>
        </div>
        <div className={styles.page_stats}></div> */
}
