import React, { useState } from "react";
import styles from "./global.module.css";

const HoverFlickerText = ({ text, className, onClick }) => {
  const [flickeredChars, setFlickeredChars] = useState([]);

  const handleMouseEnter = () => {
    const chars = text.split("").map((char, index) => ({
      char,
      style: {
        animation: `${styles.flickerEffect} ${
          Math.random() * 0.4 + 0.1
        }s infinite`,
        animationDelay: `${Math.random() * 0.1}s`,
      },
    }));
    setFlickeredChars(chars);

    // Clear flicker animation after 0.5s
    setTimeout(() => {
      setFlickeredChars([]);
    }, 350);
  };

  const handleMouseLeave = () => {
    setFlickeredChars([]);
  };

  return (
    <p
      className={`${styles.flickerText} ${className}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      {flickeredChars.length > 0
        ? flickeredChars.map((item, index) => (
            <span key={index} style={item.style}>
              {item.char}
            </span>
          ))
        : text.split("").map((char, index) => <span key={index}>{char}</span>)}
    </p>
  );
};

export default HoverFlickerText;
