import React, { useEffect } from "react";
import { useState } from "react";

import styles from "./portfolio.module.css";

import { useNavigate } from "react-router";

function CompanyTile(props) {
  const navigate = useNavigate();
  const [isHovering, setIsHovering] = useState(false);

  function handleCompanyClick() {
    props.onClick(props);
    // navigate(`/portfolio/${props.company_name}`);
  }

  return (
    <div className={styles.company_tile} onClick={handleCompanyClick}>
      <div
        className={styles.company_logo_background}
        style={{ backgroundColor: props.background_color }}
      >
        <img src={props.logo} className={styles.company_logo} />
      </div>

      <div className={styles.company_info}>
        <div className={styles.company_info_text}>
          <p className={styles.company_title}>{props.company_name}</p>
          <p className={styles.company_subtitle}>{props.sector}</p>
        </div>
        <div
          className={styles.view_button}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <img
            src={
              isHovering
                ? "/Arrows/blackFullArrow.png"
                : "/Arrows/whiteFullArrow.png"
            }
            className={styles.view_arrow}
          />
        </div>
      </div>
      <div className={styles.company_hover_info}>
        <p className={styles.company_description}>{props.description}</p>
        <div
          className={styles.info_underline}
          style={{
            borderBottom: `1px solid ${props.background_color}`,
          }}
        />
      </div>
    </div>
  );
}

export default CompanyTile;
