import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useContext } from "react";
import App from "./App";

// import NotFound from "./pages/NotFound";

import HomePage from "./pages/Landing/HomePage";
import PortfolioPage from "./pages/Landing/PortfolioPage";
import ApplyPage from "./pages/Landing/ApplyPage";
import MissionPage from "./pages/Landing/MissionPage";
import CareerPage from "./pages/Landing/CareerPage";
import FAQPage from "./pages/Landing/FAQPage";

// import SignInPage from "./pages/Authentication/SignInPage";
// import SignUpPage from "./pages/Authentication/SignUpPage";

function routes() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          {/* ROUTES FOR LANDING PAGE */}
          {/* <Route path="/" element={<App childComp={<AdminPage />} />} /> */}
          <Route path="/" element={<App childComp={<HomePage />} />} />
          <Route
            path="/portfolio"
            element={<App childComp={<PortfolioPage />} />}
          />
          <Route
            path="/portfolio/:company"
            element={<App childComp={<PortfolioPage />} />}
          />
          <Route
            path="/mission"
            element={<App childComp={<MissionPage />} />}
          />
          <Route path="/careers" element={<App childComp={<CareerPage />} />} />
          <Route
            path="/frequently-asked-questions"
            element={<App childComp={<FAQPage />} />}
          />
          <Route path="/apply" element={<App childComp={<ApplyPage />} />} />

          {/* ROUTES FOR SIGNING IN AND SIGNING UP  */}
          {/* <Route
            path="/partner-login"
            element={<App childComp={<SignInPage />} />}
          /> */}
          {/* <Route path="/signup" element={<App childComp={<SignUpPage />} />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default routes;
