import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";
import ApplyComponent from "../../components/LandingComponents/ApplyPage/ApplyComponent";

const ApplyPage = () => {
  return (
    <div>
      <LandingNavbar childComponent={<ApplyComponent />} activeText="Apply" />
    </div>
  );
};

export default ApplyPage;
