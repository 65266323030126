import React, { useEffect } from "react";
import { useState } from "react";

import styles from "./apply.module.css";

const ApplyComponent = () => {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <div className={styles.landing_page}>
      <div className={styles.page_header}>
        <div className={styles.page_titles}>
          <p className={styles.page_title}>Get in Touch</p>
          <p className={styles.page_description}>
            Are you a founder that's struggling to get to market? Get in touch
            now.
          </p>
        </div>
        <div className={styles.page_inputs}>
          <div className={styles.text_input}></div>
          <div className={styles.text_input}></div>
          <div className={styles.text_input}></div>
          <div className={styles.paragraph_input}></div>
        </div>
      </div>
      <div className={styles.page_line_break}></div>
      {/* <div className={styles.portfolio_grid}>
        {sortedPortfolio?.map((company, index) => (
          <CompanyTile key={index} {...company} />
        ))}
      </div> */}
    </div>
  );
};
//text_input
export default ApplyComponent;
