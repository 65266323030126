import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";
import MissionComponent from "../../components/LandingComponents/MissionPage/MissionComponent";

const MissionPage = () => {
  return (
    <div>
      <LandingNavbar
        childComponent={<MissionComponent />}
        activeText="Mission"
      />
    </div>
  );
};

export default MissionPage;
