import React from "react";
import { useState, useEffect } from "react";
import { useRef } from "react";

import styles from "./home.module.css";
import LandingFooter from "../../../layout/LandingFooter/footer";
import { useNavigate } from "react-router";

import lottie from "lottie-web";
import { defineElement } from "@lordicon/element";

import HomePageTop from "./HomePageTop";

import { useMediaQuery } from "@material-ui/core";

defineElement(lottie.loadAnimation);

const SectionMarker = ({ title, color }) => {
  const starpoint =
    color === "white" ? "/starpoint-white.png" : "/starpoint-black.png";

  const titleStyle =
    color === "white" ? styles.marker_title : styles.marker_title_black;

  return (
    <div className={styles.section_marker}>
      <img className={styles.star_icon} src={starpoint}></img>
      <p className={titleStyle}>{title}</p>
    </div>
  );
};

const HomeComponent = () => {
  const navigate = useNavigate();

  function handleApply() {
    navigate("/apply");
  }

  function handlePortfolio() {
    navigate("/portfolio");
  }

  const isDesktop = useMediaQuery("(min-width: 1024px)");

  // Ref for the container to hold the animation
  const clockContainer = useRef(null);
  const targetContainer = useRef(null);
  const search = useRef(null);

  useEffect(() => {
    if (clockContainer.current) {
      const animation = lottie.loadAnimation({
        container: clockContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: "/AnimatedIcons/clock-icon.json",
      });

      return () => animation.destroy();
    }
  }, []);

  useEffect(() => {
    if (targetContainer.current) {
      const animation = lottie.loadAnimation({
        container: targetContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: "/AnimatedIcons/target-enlarge.json",
      });

      return () => animation.destroy();
    }
  }, []);

  useEffect(() => {
    if (search.current) {
      const animation = lottie.loadAnimation({
        container: search.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: "/AnimatedIcons/search-icon.json",
      });

      return () => animation.destroy();
    }
  }, []);

  return (
    <div className={styles.home_components}>
      <HomePageTop />
      <div className={styles.white_section}>
        <div className={styles.banner_title_box}>
          <p className={styles.banner_title}>
            We specialize in providing a <strong>pre-product</strong> startup
            with the one thing money can't buy, <strong>time.</strong>
          </p>
        </div>
        {isDesktop ? (
          <div
            ref={clockContainer}
            style={{ width: "175px", height: "175px" }}
          ></div>
        ) : (
          <div
            ref={clockContainer}
            style={{ width: "100px", height: "100px" }}
          ></div>
        )}
      </div>

      <div className={styles.animated_horizontal_divider_start}>
        <div
          className={styles.animated_horizontal_black_divider_inverse_left}
        ></div>
      </div>

      {/* <div className={styles.divider_layout}>
        <div className={styles.black_divider}></div>
      </div> */}

      <div className={styles.white_section_why}>
        <div className={styles.banner_content}>
          <p className={styles.banner_title}>not your average VC</p>
          <p className={styles.banner_content_text}>
            Who are we? Engineers at heart. If you've landed here, chances are,
            you're fueled by an idea that demands to be brought to life. As a
            first-time founder, the journey from ideation to creation can seem
            daunting. We get it. The tech landscape is challenging, and securing
            the right talent can feel like an uphill battle. At Cose Capital, we
            bridge that gap. We don't just back your ideas; we actively
            participate in their creation, offering you the engineering power
            your vision deserves. Time is the most scarce resource for a
            pre-product startup, lets change that.
          </p>
        </div>
      </div>

      <div className={styles.animated_horizontal_divider_end}>
        <div
          className={styles.animated_horizontal_black_divider_inverse_right}
        ></div>
      </div>

      {/* <div className={styles.divider_layout}>
        <div className={styles.black_divider}></div>
      </div> */}

      <div className={styles.white_section_large}>
        <div className={styles.left_section}>
          <div className={styles.section_top_layout}>
            <SectionMarker title="PROCESS" color="black" />
            <p className={styles.section_top_title_black}>Our approach</p>
          </div>
          <div className={styles.target_section}>
            {isDesktop ? (
              <div
                ref={targetContainer}
                style={{
                  width: "275px",
                  height: "275px",
                }}
              ></div>
            ) : (
              <div
                ref={targetContainer}
                style={{ width: "105px", height: "105px" }}
              ></div>
            )}
          </div>
        </div>
        <div className={styles.right_section}>
          <div className={styles.approach_step}>
            <p className={styles.step_number}>.01</p>
            <p className={styles.step_title}>Discovery</p>
            <p className={styles.step_description}>
              We start by deeply understanding our partner companies’ visions
              and technical needs through a comprehensive discovery process.
              This foundational step ensures our collaboration is well-informed
              and aligned with strategic goals, setting the stage for effective
              and targeted support.
            </p>
          </div>
          <div className={styles.step_divider} />
          <div className={styles.approach_step}>
            <p className={styles.step_number}>.02</p>
            <p className={styles.step_title}>Research</p>
            <p className={styles.step_description}>
              Our team of elite engineers dives deep into research, uncovering
              innovative technical solutions specifically designed to meet the
              unique challenges faced by our partners. This step is all about
              laying a strong foundation for the product, making sure we're not
              just up-to-date but ahead of the curve, using the latest
              technologies and practices that set industry standards.
            </p>
          </div>
          <div className={styles.step_divider} />
          <div className={styles.approach_step}>
            <p className={styles.step_number}>.03</p>
            <p className={styles.step_title}>Build</p>
            <p className={styles.step_description}>
              In the build phase, we bring our top engineering talent to the
              table, working hand-in-hand with our partners. This isn't just
              about speeding up the development process; it's about pushing the
              boundaries of quality and precision. By merging our expertise with
              that of our partners, we ensure the final product isn't just
              market-ready—it's a step ahead, known for its innovation, speed,
              and unmatched quality.
            </p>
          </div>
        </div>
      </div>

      <div className={styles.black_section}>
        <div className={styles.section_full_top_layout}>
          <div className={styles.section_top_layout}>
            <SectionMarker title="OUR FOCUS" color="white" />
            <p className={styles.section_top_title}>Sectors of interest</p>
          </div>
          <div className={styles.animated_search_section}>
            {isDesktop ? (
              <div
                ref={search}
                style={{
                  width: "175px",
                  height: "175px",
                }}
              ></div>
            ) : (
              <div
                ref={search}
                style={{ width: "105px", height: "105px" }}
              ></div>
            )}
          </div>
        </div>

        <div className={styles.section_sector_content}>
          {/* <div className={styles.vertical_divider}></div> */}
          <div className={styles.investment_sector}>
            <div className={styles.investment_sector_layout}>
              <p className={styles.investment_sector_title}>SaaS + Cloud</p>
              <p className={styles.investment_sector_description}>
                In the realm of SaaS and Cloud Computing, we champion platforms
                that are pioneers in scalability, reliability, and security. Our
                focus is on technologies that act as growth partners, providing
                adaptability and comprehensive support. We're committed to
                solutions that foster trust and loyalty, enabling businesses to
                flourish in a cloud-first world. Our focus zeroes in on
                platforms that redefine scalability and reliability.
              </p>
            </div>
          </div>
          <div className={styles.vertical_divider}></div>
          <div className={styles.investment_sector}>
            <div className={styles.investment_sector_layout}>
              <p className={styles.investment_sector_title}>Mobile + Desktop</p>
              <p className={styles.investment_sector_description}>
                Our methadology in Mobile and Desktop solutions revolves around
                elevating user engagement through exceptional design and smart
                utilization of technology. We support applications that merge
                user insights with innovation, setting new standards in
                usability and satisfaction. Our goal is to make mobile and
                desktop experiences more intuitive and innovative, all within
                the cloud's expansive reach.
              </p>
            </div>
          </div>
          <div className={styles.vertical_divider}></div>
          <div className={styles.investment_sector}>
            <div className={styles.investment_sector_layout}>
              <p className={styles.investment_sector_title}>AI + Robotics</p>
              <p className={styles.investment_sector_description}>
                In Artificial Intelligence and Robotics, we aim to be active
                enablers of the future. Our interest lies in ventures that
                bridge the gap between advanced AI theories and practical
                robotics applications. We partner in ventures that lead the
                charge towards intelligent, adaptive solutions, aiming to
                transform industries and improve lives. Our commitment is to
                reshape how advanced technology enhances human potential."
              </p>
            </div>
          </div>

          {/* <div className={styles.vertical_divider}></div> */}
        </div>
      </div>
      <div className={styles.white_section_apply}>
        <div className={styles.apply_layout}>
          <p className={styles.apply_section_text}>
            We give first time founders an edge
          </p>
          {isDesktop ? (
            <p className={styles.apply_section_tagline}>
              Create Faster. Build Better. Extend Your Runway.
            </p>
          ) : (
            <p className={styles.apply_section_blurbs}>
              <div className={styles.divider} />
              <p className={styles.blurb}>Create Faster.</p>
              <div className={styles.divider} />
              <p className={styles.blurb}>Build Better.</p>
              <div className={styles.divider} />
              <p className={styles.blurb}>Extend Your Runway.</p>
              <div className={styles.divider} />
            </p>
          )}

          <div className={styles.apply_section_button}>Apply</div>
        </div>
      </div>
      <div className={styles.black_section_content_extended}>
        <div className={styles.black_section_content}>
          <div className={styles.black_section_content_left}>
            <p className={styles.black_section_content_title}>
              Simplifying Success.
            </p>
            <p className={styles.black_section_content_text}>
              The first step is always the hardest. We're here to make it
              easier, kickstarting you with the knowledge and resources to
              navigate the startup ecosystem confidently. We transform obstacles
              into opportunities, helping you make informed decisions at every
              turn.
            </p>
          </div>
          <div className={styles.black_section_content_right}>
            <img
              className={styles.glass_image}
              src="/AbstractGlass/glass-image-16.png"
            ></img>
          </div>
        </div>
        <div className={styles.black_section_content}>
          <div className={styles.black_section_content_right}>
            <img
              className={styles.glass_image}
              src="/AbstractGlass/glass-ribbon-12.png"
            ></img>
          </div>
          <div className={styles.black_section_content_left}>
            <p className={styles.black_section_content_title_end}>
              Designing Tomorrow With Precision.
            </p>
            <p className={styles.black_section_content_text_end}>
              The path from idea to execution is fraught with challenges. We'll
              be your partner in deconstructing this path, providing the
              insights and assistance you need to turn your vision into reality.
              We're not just investing in your idea; we're committed to building
              it with you.
            </p>
          </div>
        </div>

        <div className={styles.black_section_content}>
          <div className={styles.black_section_content_left}>
            <p className={styles.black_section_content_title}>
              Turning Complexity into Clarity.
            </p>
            <p className={styles.black_section_content_text}>
              We understand that stepping into the unknown can be overwhelming.
              Our mission is to guide you through the intricacies of startup
              development, making the complex aspects of your journey feel
              familiar and manageable. Together, we transform the unintuitive
              into the intuitive.
            </p>
          </div>
          <div className={styles.black_section_content_right}>
            <img
              className={styles.glass_image}
              src="/AbstractGlass/glass-11.png"
            ></img>
          </div>
        </div>
      </div>
      {/* <div className={styles.white_divider}></div> */}
      <LandingFooter />
    </div>
  );
};

export default HomeComponent;

{
  /* <div className={styles.landing_stat}>
            <div className={styles.landing_stat_layout}>
              <p className={styles.landing_stat_value}>9</p>
              <p className={styles.landing_stat_title}>Companies</p>
            </div>
          </div>
          <div className={styles.landing_stat}>
            <div className={styles.landing_stat_layout}>
              <p className={styles.landing_stat_value}>5000+</p>
              <p className={styles.landing_stat_title}>Hours Invested</p>
            </div>
          </div>
          <div className={styles.landing_stat}>
            <div className={styles.landing_stat_layout}>
              <p className={styles.landing_stat_value}>3</p>
              <p className={styles.landing_stat_title}>Partners</p>
            </div>
          </div>
          <div className={styles.landing_stat}>
            <div className={styles.landing_stat_layout}>
              <p className={styles.landing_stat_value}>20+</p>
              <p className={styles.landing_stat_title}>Years of Expierence</p>
            </div>
          </div> */
}
