import React, { useEffect } from "react";
import { useState } from "react";

import styles from "./portfolio.module.css";

import { useNavigate } from "react-router";

import CompanyPage from "./CompanyPage";
import CompanyTile from "./CompanyTile";
import LandingFooter from "../../../layout/LandingFooter/footer";

import { portfolio_companies } from "./portfolio_companies";

const PortfolioComponent = () => {
  const [isViewingCompany, setIsViewingCompany] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const handleCompanyClick = (company) => {
    setSelectedCompany(company);
    setIsViewingCompany(true);
  };

  const handleCloseCompanyPage = () => {
    setIsViewingCompany(false);
  };

  const sortedPortfolio = portfolio_companies.sort((a, b) => {
    const companyA = a.company_name.toLowerCase();
    const companyB = b.company_name.toLowerCase();

    if (companyA < companyB) {
      return -1;
    }
    if (companyA > companyB) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      {isViewingCompany && (
        <CompanyPage
          className={styles.slideIn}
          company={selectedCompany}
          onClose={handleCloseCompanyPage}
        />
      )}
      <div
        className={`${styles.landing_page} ${
          isViewingCompany ? styles.slideOut : ""
        }`}
      >
        <div className={styles.page_header}>
          <div className={styles.page_titles}>
            <p className={styles.page_title}>Our Portfolio</p>
            <p className={styles.page_subtitle}>
              innovation = invention + commercialization
            </p>
            <p className={styles.page_description}>
              The essence of our philosophy, highlighting the necessity of
              transforming groundbreaking ideas into viable market solutions. By
              championing this blend of creativity and market viability, we
              ensure our portfolio consists of ventures that not only pioneer
              new technologies but also excel in introducing them effectively to
              the global stage, setting new standards in their respective
              industries.
            </p>
          </div>
          <div className={styles.page_stats}></div>
        </div>
        <div className={styles.page_line_break}></div>
        <div className={styles.portfolio_grid}>
          {sortedPortfolio?.map((company, index) => (
            <CompanyTile
              key={index}
              {...company}
              onClick={handleCompanyClick}
            />
          ))}
        </div>
      </div>

      {/* <LandingFooter /> */}
    </>
  );
};

export default PortfolioComponent;
