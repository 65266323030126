import React, { useEffect } from "react";
import { useState } from "react";

import styles from "./mission.module.css";

import LandingFooter from "../../../layout/LandingFooter/footer";

const MissionComponent = () => {
  return (
    <>
      <div className={styles.landing_page}>
        <div className={styles.page_header}>
          <div className={styles.page_titles}>
            <p className={styles.page_title}>Our Mission</p>
            <p className={styles.page_description}>
              Our mission is to empower founders, transforming their ideas into
              impactful realities. We celebrate innovation and invention. The
              key drivers of industry and societal advancement. Invention brings
              new ideas to life, while innovation refines and improves existing
              ones, enhancing efficiency, accessibility, and effect. We believe
              that both are not just beneficial but essential for progress.
            </p>
          </div>
          <div className={styles.page_stats}></div>
        </div>
        <div className={styles.page_line_break}></div>
        <div className={styles.mission_page_content}>
          <div className={styles.page_section}>
            <div className={styles.left_section}>
              <img
                className={styles.section_image_big}
                // src="/StockImages/world.png"
                src="/StockImages/abstract-2.png"
              ></img>
            </div>
            <div className={styles.right_section}>
              <p className={styles.section_title}>
                What No One Tells You Starting Out
              </p>
              <p className={styles.section_text}>
                Starting out as a first-time tech founder, you're on a journey
                where the odds are against you. It's a path filled with
                unexpected challenges, the "unknown unknowns". Navigating
                through these waters requires more than just determination; it
                demands resilience, the courage to face the unforeseen, and the
                agility to adapt.<br></br> <br></br>Amidst this journey, our
                mission is clear: to stand beside you, lighting the way from
                concept to creation. We aim to simplify the complexities, making
                what once seemed insurmountable, achievable. Together, we turn
                daunting odds into stepping stones towards innovation and
                success.
              </p>
            </div>
          </div>
          <div className={styles.section_space_divider}></div>
          <div className={styles.page_section}>
            <div className={styles.left_section}>
              <p className={styles.section_title}>Simple vs Easy</p>
              <p className={styles.section_text}>
                In tech entrepreneurship, distinguishing "simple" from "easy" is
                key. "Simple" refers to clear, straightforward concepts, whereas
                "easy" suggests minimal effort or resources are needed for
                execution. This is critical for founders since some ideas,
                though simple in theory, can be tough to execute. Understanding
                this difference promotes careful planning, skill development,
                and resilience, ensuring founders have realistic expectations
                for their innovative journey.
              </p>
            </div>
            <div className={styles.right_section}>
              <img
                className={styles.section_image}
                src="/StockImages/abstract-1.png"
              ></img>
            </div>
          </div>
          <div className={styles.section_space_divider}></div>
          <div className={styles.bottom_section}>
            <div className={styles.bottom_section_left}>
              <img
                className={styles.vertical_image}
                src="/AbstractLightImages/random-abstract-4.jpeg"
              ></img>
              <p className={styles.section_title}>The Execution</p>
              <p className={styles.long_text}>
                At the heart of every groundbreaking idea is exceptional
                execution. It’s the bridge between a visionary idea and its
                transformation into a tangible, impactful reality. Execution is
                where strategy meets action, and where the complexity of
                bringing an idea to life is managed through meticulous planning,
                relentless focus, and unwavering dedication. Our approach is
                grounded in practicality, embracing both agility and resilience,
                to navigate the intricate journey from concept to market. We
                empower founders by providing the tools, knowledge, and support
                needed to execute with precision, ensuring that every step taken
                is a step closer to achieving their vision. In the world of tech
                entrepreneurship, execution is not just about launching a
                product; it’s about creating value that resonates, endures, and
                inspires.
              </p>
            </div>
            <div className={styles.bottom_section_right}>
              <div className={styles.text_boxes}>
                <p className={styles.section_title}>The Vision</p>
                <p className={styles.long_text}>
                  Our vision is to be the catalyst for a world where technology
                  and creativity merge to solve the most pressing challenges of
                  our time. We envision a future where innovation is not just
                  celebrated but integrated into the very fabric of society,
                  making life better for all. Through our commitment to
                  supporting founders and innovators, we aim to foster an
                  ecosystem where ideas can flourish, barriers are broken, and
                  possibilities are endless. We believe in a world where
                  technology serves as a force for good, transforming lives,
                  empowering communities, and shaping the future. Our vision is
                  bold, yet it is within reach through collaboration,
                  innovation, and the relentless pursuit of excellence.
                  Together, we can build a future that reflects our highest
                  aspirations, where every innovation makes a difference, and
                  every success story starts with a vision turned into reality.
                </p>
              </div>
              <img
                className={styles.horizontal_image}
                src="/AbstractLightImages/random-abstract-1.jpeg"
              ></img>
            </div>
          </div>
        </div>
        <div className={styles.section_line_break}></div>
        <div className={styles.blueprint_section}>
          <p className={styles.blueprint_section_title}>
            The Product Blueprint
          </p>
          <p className={styles.blueprint_section_subtitle}>
            Remember the concept of simple vs easy? Here's the simple part.
          </p>
          <div className={styles.blueprint_section_items}>
            <div className={styles.blueprint_item}>
              <p className={styles.blueprint_section_number}>01</p>
              <p className={styles.blueprint_section_stage}>
                Build your product
              </p>
              <p className={styles.blueprint_section_description}>
                Bring your vision to life with unwavering dedication. As a
                founder, you're the architect of innovation, crafting solutions
                that resonate.
              </p>
            </div>
            <div className={styles.blueprint_item}>
              <p className={styles.blueprint_section_number}>02</p>
              <p className={styles.blueprint_section_stage}>
                Sell your product
              </p>
              <p className={styles.blueprint_section_description}>
                Capture the market with your unique vision. For founders, it's
                about more than selling a product—it's about sharing a part of
                your dream.
              </p>
            </div>
            <div className={styles.blueprint_item}>
              <p className={styles.blueprint_section_number}>03</p>
              <p className={styles.blueprint_section_stage}>
                Scale your product
              </p>
              <p className={styles.blueprint_section_description}>
                Embark on the journey of growth with confidence. For founders,
                scaling is the path to transforming a vision into a legacy.
                Let's elevate your mission.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.section_line_break}></div>
      </div>
      <LandingFooter />
    </>
  );
};

export default MissionComponent;
