export const portfolio_companies = [
  {
    logo: "/CompanyLogos/SoundbarLogo.png",
    background_color: "rgba(254, 215, 0, 0.2)",
    company_name: "Soundbar",
    description:
      "Revolutionizing music sharing and discovery, Soundbar is the TikTok for music enthusiasts, offering a new platform to express and connect. Founded in 2020, it's reshaping how we experience music and social media together.",
    sector: "Music • Social Media",
    year: "2020",
  },
  // {
  //   logo: "/CompanyLogos/OrdreLogo.png",
  //   background_color: "rgba(217, 151, 60, 0.4)",
  //   company_name: "Ordre",
  //   description:
  //     "With Ordre, experience the future of dining by scanning, ordering, and paying without ever needing to speak to a server. Launched in 2022, it's pioneering the way towards seamless, contactless hospitality experiences.",
  //   sector: "Hospitality • Contactless",
  //   year: "2022",
  // },
  {
    logo: "/CompanyLogos/BrandLinkLogo.png",
    background_color: "rgba(0, 88, 220, 0.2)",
    company_name: "Brandlink",
    description:
      "Brandlink empowers businesses and creators by enabling advanced searches across a database of 300+ million profiles to find the perfect collaboration partner. Introduced in 2023, it's transforming marketing through powerful data insights.",
    sector: "Data • Marketing",
    year: "2023",
  },
  {
    logo: "/CompanyLogos/SubletyLogo.png",
    background_color: "rgba(1, 68, 33, 0.2)",
    company_name: "Sublety",
    description:
      "Sublety simplifies the subletting process, allowing users to effortlessly find or post sublets. Established in 2023, it's carving a niche in the real estate market with its user-friendly platform.",
    sector: "Niche • Real Estate",
    year: "2023",
  },
  {
    logo: "/CompanyLogos/RealRivalsLogo.png",
    background_color: "rgba(134, 176, 73, 0.4)",
    company_name: "RealRivals",
    description:
      " RealRivals offers a unique platform where individuals can meet to play real, ranked sports games. Launched in 2023, it brings competition and community together in the sports and leisure sector..",
    sector: "Sports • Leisure",
    year: "2023",
  },
  {
    logo: "/CompanyLogos/ArtemisLogo.png",
    background_color: "rgba(255, 255, 255, 0.4)",
    company_name: "Artemis",
    description:
      "Inspired by the artificial intelligence, Jarvis - from Iron Man. Artemis aims to reinvent smart home living with cutting-edge AI and robotics. Starting in 2024, it's on a mission to transform everyday life into something extraordinary.",
    sector: "AI • Robotics",
    year: "2024",
  },
  // {
  //   logo: "/CompanyLogos/MarketerLogo.png",
  //   background_color: "rgba(3, 70, 200, 0.4)",
  //   company_name: "Marketer",
  //   description:
  //     "Marketer democratizes advertising by enabling anyone to sell the top corner of a social media post. Founded in 2022, it's revolutionizing marketing by tapping into unused digital spaces.",
  //   sector: "Marketing • Democratization",
  //   year: "2022",
  // },
  // {
  //   logo: "/CompanyLogos/VacayteLogo.png",
  //   background_color: "rgba(218, 107, 110, 0.4)",
  //   company_name: "Vacayte",
  //   description:
  //     "Vacayte introduces a novel concept to the sharing economy by facilitating home swaps instead of traditional rentals. Since 2022, it's redefining hospitality with a unique twist on staying and exploring new places.",
  //   sector: "Real Estate • Hospitality",
  //   year: "2022",
  // },
  {
    logo: "/CompanyLogos/NyteLyfeLogo.png",
    background_color: "rgba(136, 8, 8, 0.4)",
    company_name: "NyteLyfe",
    description:
      "Supercharge your research leveraging computer vision. NyteLyfe will scan and analyze your environment to provide instant information. Established in 2023, it combines robotics and environmental awareness to monitor all from ESG to Wildlife to Insects.",
    sector: "Robotics • Environmental",
    year: "2023",
  },
  {
    logo: "/CompanyLogos/NexusLogo.png",
    background_color: "rgba(255, 227, 122, 0.4)",
    company_name: "Nexus",
    description:
      "Nexus provides a white-label solution that enables any organization to launch a custom social media application within minutes. Introduced in 2023, it's simplifying social media management and engagement for brands and communities.",
    sector: "White Label • Social Media",
    year: "2023",
  },
];

// {
//   logo: "/example_logo.png",
//   background_color: "#fed700",
//   company_name: "ReplyAssist",
//   description: "Enter in the basics of what you want in a post.",
//   sector: "SAAS",
//   type: "Hard Tech",
//   year: "2024",
// },
