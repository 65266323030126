import React, { useEffect } from "react";
import { useState } from "react";

import styles from "./faq.module.css";

const faq_data = [
  {
    question: "Where is ReplyAssist based out of as a company?",
    answer: (
      <p className={styles.answer_text}>
        <strong>A: Chicago, Illinois.</strong>
        <br />
        <br />
        We know that a lot of technology companies are based out of other
        countries, and that would be a major concern to you as a US-based
        advisor.
        <br />
        <br />
        Our founders, <strong>Austin McCulloh (CEO)</strong> and{" "}
        <strong>Justin Cose (CTO)</strong>, are from Iowa and Illinois,
        respectively, so we completely understand how important it is for you to
        work with a US-based company (since United States Financial Advisors
        only work with United States residents, in general).
        <br />
        <br />
      </p>
    ),
  },
  {
    question: "Is ReplyAssist trying to replace me as a Financial Advisor? ",
    answer: (
      <p className={styles.answer_text}>
        <strong>A: Nope!</strong>
        <br />
        <br />
        We believe that most Financial Advisors constantly have to juggle two
        roles, which isn’t optimal. These two roles are:
        <br />
        <br />
        1. Being a Financial Advisor.
        <br />
        2. Being a Salesperson.
        <br />
        <br />
        So, the aim of ReplyAssist is to reduce your work as a Salesperson so
        you can put more time into being the best Financial Advisor possible for
        your clients. We aren’t trying to replace you at all - ReplyAssist is
        VERY different from a robo advisor. In fact, our intention is to free up
        your time to make you an even better Financial Advisor! To elaborate on
        this point about Artificial Intelligence, here’s a quote from Hubspot’s
        CMO, Kipp Bodnar, that is from their “The State of AI in Marketing in
        2023” report:
        <br />
        <br />
        <span className={styles.hubspot_quote_text}>
          “AI will never replace human creativity and connection. Lived
          experiences and opinions will never be able to be generated by a
          machine, and an employee can’t be mentored and managed by AI. AI is a
          great tool for marketers to use, but would be useless without human
          knowledge, strategy, and implementation.{" "}
        </span>
        <strong>
          But when it comes to menial tasks that take up way too many of our
          hours — let’s leave those to the robots.“
        </strong>
        <br />
        <br />
      </p>
    ),
  },
  {
    question:
      "How do I know ReplyAssist will work for me as a Financial Advisor?",
    answer: (
      <p className={styles.answer_text}>
        <strong>A: Multiple reasons.</strong>
        <br />
        <br />
        1. Our executive team has worked with financial advisors, just like you,
        for over 7 years, which includes running a digital marketing firm from
        2020 - 2023. During those years, we generated 54,000+ leads (lead = a
        response from an ideal prospect) for financial advisor clients. This has
        resulted in thousands of meetings set for those clients.
        <br />
        <br />
        2. Our Founder & CEO, Austin McCulloh, was a licensed Financial Advisor
        for multiple years, so he understands the most common objections &
        questions a financial representative (you) will hear from a prospect.
        <br />
        <br />
        3. Austin has sent 35,000+ Invites on LinkedIn, as well as he has
        responded to over 12,000 Direct Message conversations with prospects on
        LinkedIn personally. This has resulted in over 650 meetings set from
        LinkedIn with an 80.62% meeting show-up rate.
        <br />
        <br />
        <strong>To summarize</strong> - We understand the problems you face when
        prospecting, as well as how to overcome them based on our real-world
        experience. So we have accounted for that while building ReplyAssist in
        order to make it sound as human as possible. And we created ReplyAssist
        so that it knows how to communicate in an effective way, such as making
        sure it isn't too salesy, doesn't send super long messages (paragraphs),
        avoids talking about market returns, etc.
        <br />
        <br />
      </p>
    ),
  },
  {
    question:
      "I’m a Financial Advisor, not just a Life Insurance Agent. How will the chatbot know the difference?",
    answer: (
      <p className={styles.answer_text}>
        <strong>
          A: During the account setup, you tell ReplyAssist the type of products
          you offer, so the chatbot knows the difference.
        </strong>
        <br />
        <br />
        Part of the Account Setup process for each new customer is to ask for
        your input on factors such as your objectives of using ReplyAssist &
        what products/services you offer to your clients. Which means, your
        ReplyAssist chatbot will be tailored specifically to you.
        <br />
        <br />
        Based on our years of experience of working with thousands of Financial
        Advisors, Life Insurance Agents, etc., we understand that the way a
        Financial Advisor sells to a prospect is not the same way a Life
        Insurance Agent sells to a prospect, so we built the tool with this in
        mind. In addition, we know that you have far more compliance rules to
        follow as a Financial Advisor due to your securities licenses, and we
        factored in these compliance rules when creating the chatbot.
        <br />
        <br />
      </p>
    ),
  },
  {
    question: "How much control do I have over this intelligent assistant?",
    answer: (
      <p className={styles.answer_text}>
        <strong>A: About as much as you need. </strong>
        <br />
        <br />
        For example, you can set “Working Hours” so ReplyAssist knows when to
        work for you vs. when to be turned off. And you’ll have other
        customization such as the Automated Responses vs. the Autogenerated
        Responses features, which you can see below. You will be able to control
        how ReplyAssist works for you in your Chatbot Settings.
        <br />
        <br />
        <strong>1. Automated Responses</strong> We know that some of our
        customers trust ReplyAssist and want to save as much time as possible,
        so they “turn on” the automated responder rather than reviewing
        ReplyAssist’s work. With this feature, ReplyAssist will respond on your
        behalf without you needing to type out the message or send it to the
        prospect.
        <br />
        <br />
        <strong>2. Autogenerated Responses</strong> On the flip side, some of
        our customers are a bit cautious when first using ReplyAssist, so they’d
        rather take a few extra minutes to review the replies that ReplyAssist
        autogenerates for them, rather than using the Automated Responses
        feature. All you do is click our “Generate Response” button in the Inbox
        page of your user login portal, and then you have the choice to 1. Send
        that exact autogenerated response to your prospect, 2. Edit the reply
        that ReplyAssist gives you and then send it to your prospect, or 3.
        Generate another response from ReplyAssist if you don’t like the first
        autogenerated response. If you want a bit more control over your
        prospecting, you’ll lean towards using this Autogenerated Responses
        features over the Automated Responses feature.
        <br />
        <br />
      </p>
    ),
  },
  {
    question:
      "How do I know ReplyAssist won’t get me in trouble with compliance?",
    answer: (
      <p className={styles.answer_text}>
        <strong>
          A: We have safeguards in place to ensure ReplyAssist is communicating
          within compliance guidelines.{" "}
        </strong>
        <br />
        <br />
        The last thing we want is for you to get in trouble with your leadership
        team and/or company, so we have been extremely diligent with putting
        safeguards, rules, & parameters in place to make sure ReplyAssist is as
        compliant as possible.
        <br />
        <br />
        Additionally, we go by this rule of thumb / philosophy of thinking:
        <br />
        <br />
        <strong>
          “If it’s going to get you in trouble with compliance, it’s not
          effective to use when prospecting.”{" "}
        </strong>
        <br />
        <br />
        Meaning, anything that will get you in trouble with compliance is too
        “salesy” in the first place. For example, if you’re trying to talk about
        stock market returns with prospects while messaging on LinkedIn, you are
        getting too ahead of yourself. The purpose of prospecting on LinkedIn is
        to get your prospects: 1. Curious, and 2. Interested. So, once a
        prospect begins to show interest in learning more, the best next steps
        are to schedule a video/phone call together. The act of providing
        in-depth answers, as well as selling, is meant to be done on the meeting
        with a prospect, NOT over LinkedIn Direct Messages (DMs).
        <br />
        <br />
        <br />
        Important note: Given the nature of using Artificial Intelligence, we
        are not able to guarantee with 100% certainty the effectiveness of our
        chatbot. Which means, we cannot guarantee that there is no risk
        associated with using ReplyAssist in regard to following compliance
        guidelines. Although we used our experience of being licensed in the
        financial services industry in the past to keep compliance guidelines in
        mind when creating ReplyAssist, Artificial Intelligence is not perfect,
        so there is a chance for miswordings & errors. If you are excited about
        the beneifts of ReplyAssist and still want to use the software, but are
        cautious about compliance, we recommend you turn “off” our Automated
        Responses feature and use the Autogenerated Responses feature. That way,
        you still get the full benefit of this highly personalized & intelligent
        prospecting assistant, but you also still get to review the
        Autogenerated Responses before sending the messages to prospects. This
        way, you are the “buffer” between ReplyAssist and your prospects.
        <br />
      </p>
    ),
  },
  {
    question:
      "Why not just save the money & do all of this prospecting work on my own?",
    answer: (
      <p className={styles.answer_text}>
        <strong>
          A: Well, do you value your time or your money more? You can always
          make more money, but you can’t get time back.
        </strong>
        <br />
        <br />
        Sure, you can save some money each month by not using ReplyAssist, but
        we don’t believe it’s worth your time or effort to do all of your
        prospecting work on your own. Responding to hundreds of LinkedIn Direct
        Messages and doing hundreds of follow up tasks in your CRM each month is
        time consuming, and ReplyAssist will save you roughly 40 hours per month
        by reducing these tasks for you (67% of your total prospecting work).
        For example, if you make $60/hour, that is $2,400 saved each month in
        terms of your time. Imagine what else you could do with 40 extra hours
        each month…
        <br />
        <br />
      </p>
    ),
  },
  {
    question: "How do I know LinkedIn prospecting even works?",
    answer: (
      <p className={styles.answer_text}>
        <strong>
          A: When it really comes down to it, ANY prospecting method can work IF
          it is done the proper way. For example, our Founder & CEO, Austin
          McCulloh, hates cold calling and knocking door-to-door to prospect,
          BUT he understands that these methods also work IF done the correct
          way.{" "}
        </strong>
        <br />
        <br />
        With LinkedIn, you can’t be quite as “direct” in your prospecting
        approach because you need to build rapport first. But you can build a
        relationship on any social media platform - you don’t need to meet in
        person to build a quality relationship. The way to build a relationship
        online is different from building a relationship in person, and we kept
        this in mind while creating ReplyAssist to ensure the intelligent
        assistant is effective and works the way it’s supposed to.
        <br />
        <br />
        To circle back to cold calling & door knocking, we do believe LinkedIn
        is MORE effective. As the years move on, fewer and fewer people are
        willing to answer calls from strangers (or phone calls in general). The
        same concept applies to door-knocking. Plus, both cold calling and
        door-knocking, in general, tend to be more time-consuming than messaging
        on social media, and they require more effort.
        <br />
        <br />
      </p>
    ),
  },
  {
    question:
      "What if one of my prospects asks if they’re talking to a robot or chatbot?",
    answer: (
      <p className={styles.answer_text}>
        <strong>
          A: If this does happen (which we expect is rare), we have designed
          ReplyAssist to notify you.{" "}
        </strong>
        <br />
        <br />
        Giving your prospects peace of mind is important, so we suggest that you
        personally respond back to that specific prospect to assure them that,
        at the end of the day, they would be working with a human (you), not a
        robot. And don't worry, our notifications system will make it clear
        which conversations require your manual input vs. those that don't
        require your input.
      </p>
    ),
  },
];

const FAQComponents = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.faq_components}>
      <div className={styles.faq_content}>
        <p className={styles.title}>Frequently Asked Questions</p>
        <p className={styles.subtitle}>
          Everything that you need to know about Cose Capital
        </p>
        <div className={styles.faq_list_items}>
          {faq_data.map((item, index) => (
            <div key={index}>
              <div
                className={styles.faq_tile}
                onClick={() =>
                  setExpandedIndex(index === expandedIndex ? null : index)
                }
              >
                <p className={styles.item_question}>{item.question}</p>
                <img
                  className={`${styles.down_arrow_image} ${
                    expandedIndex === index ? styles.flipped : ""
                  }`}
                  src="/images/down-arrow.png"
                  alt="expand"
                ></img>
              </div>
              {expandedIndex === index && (
                <div className={styles.faqanswer}>{item.answer}</div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.spacer}></div>
    </div>
  );
};

export default FAQComponents;
