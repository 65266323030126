import React from "react";
import styles from "./footer.module.css";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import HoverFlickerText from "../../components/LandingComponents/Global/HoverFlickerText";
import { useMediaQuery } from "@material-ui/core";

const LandingFooter = () => {
  // const { addUserToNewsletter } = useContext(AuthContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [newsletterSubcribed, setNewsletterSubcribed] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  function handleJoinWaitlist() {
    navigate("/joinwaitlist");
  }

  function handleFAQ() {
    navigate("/FAQ");
  }

  const footer_menus = {
    About: ["Portfolio", "Mission", "FAQ"],
    Careers: ["Head Hunters", "Operations", "Engineers", "Marketing"],
    Contact: ["Apply", "Contact Us", "Terms of Use", "Privacy Policy"],
  };

  const renderMenus = () => (
    <div className={styles.menu_container}>
      {Object.entries(footer_menus).map(
        ([categoryName, items], categoryIndex) => (
          <div key={categoryIndex} className={styles.footer_menu}>
            <p className={styles.footer_menu_title}>{categoryName}</p>
            {items.map((item, itemIndex) => (
              <p key={itemIndex} className={styles.footer_menu_item}>
                {item}
              </p>
            ))}
          </div>
        )
      )}
    </div>
  );

  const renderDesktopFooter = () => (
    <div className={styles.footer}>
      <div className={styles.footer_top}>
        <div className={styles.footer_left_content}>
          <div className={styles.footer_left_content_text}>
            {/* <p className={styles.footer_title}>Cose Capital</p> */}
            <img
              className={styles.footer_logo}
              src="/CoseCapitalLogoBlack.png"
            ></img>
            <p className={styles.footer_tagline}>architects of the future</p>
            <p className={styles.footer_description}>
              Our mission is to empower first-time tech founders by turning
              their visions into significant achievements. We champion both
              innovation and invention; The pillars of tech industry and
              societal progress. While invention introduces fresh concepts,
              innovation polishes existing ideas, boosting their efficiency and
              reach. We see these elements as vital for the success and
              advancement of new entrepreneurs.
            </p>
          </div>
        </div>
        <div className={styles.footer_right_content}> {renderMenus()} </div>
      </div>

      <HoverFlickerText
        text="build@cosecapital.com"
        className={styles.email_text}
      />
      <div className={styles.bottom_divider} />
      <div className={styles.footer_bottom}>
        <div className={styles.footer_socials}>
          <SocialMediaIcons />
        </div>
        <p className={styles.copyright_text}>
          Copyright 2024 Cose Capital. All Rights Reserved.
        </p>
      </div>
    </div>
  );

  const renderMobileFooter = () => (
    <div className={styles.footer_mobile}>
      <div className={styles.footer_top_mobile}>
        <div className={styles.footer_left_content_mobile}>
          <div className={styles.footer_left_content_text_mobile}>
            {/* <p className={styles.footer_title}>Cose Capital</p> */}
            <img
              className={styles.footer_logo}
              src="/CoseCapitalLogoBlack.png"
            ></img>
            <p className={styles.footer_tagline}>architects of the future</p>
            <p className={styles.footer_description_mobile}>
              Our mission is to empower founders, transforming their ideas into
              impactful realities. We celebrate innovation and invention—key
              drivers of industry and societal advancement. Invention brings new
              ideas to life, while innovation refines and improves existing
              ones, enhancing efficiency, accessibility, and effect. We believe
              that both are not just beneficial but essential for progress.
            </p>
          </div>
        </div>
        {/* <div className={styles.footer_right_content}> {renderMenus()} </div> */}
      </div>

      <HoverFlickerText
        text="build@cosecapital.com"
        className={styles.email_text_mobile}
      />
      <div className={styles.bottom_divider} />
      <div className={styles.footer_bottom_mobile}>
        <div className={styles.footer_socials}>
          <SocialMediaIcons />
        </div>
        <p className={styles.copyright_text_mobile}>
          Copyright 2024 Cose Capital. All Rights Reserved.
        </p>
      </div>
    </div>
  );

  return isMobile ? renderMobileFooter() : renderDesktopFooter();

  // return renderDesktopFooter();
};

function SocialMediaIcons() {
  return (
    <div className={styles.footer_social_icons}>
      <a
        href="https://www.instagram.com/cosecapital/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className={styles.social_icon}
          src="/GrayscaleSocialMediaLogos/instagram.png"
          alt="icon"
        ></img>
      </a>
      <a
        href="https://www.facebook.com/cosecapital/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className={styles.social_icon}
          src="/GrayscaleSocialMediaLogos/facebook.png"
          alt="icon"
        ></img>
      </a>
      <a
        href="https://www.linkedin.com/company/cose-capital"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className={styles.social_icon}
          src="/GrayscaleSocialMediaLogos/linkedin.png"
          alt="icon"
        ></img>
      </a>
      <a
        href="https://twitter.com/cosecapital"
        target="_blank"
        rel="noreferrer"
      >
        <img
          className={styles.social_icon}
          src="/GrayscaleSocialMediaLogos/twitter.png"
          alt="icon"
        ></img>
      </a>
    </div>
  );
}

export default LandingFooter;

// const renderMobileFooter = () => (
//   // Your mobile-specific footer JSX here
//   <div className={styles.footer_mobile}>
//     <p className={styles.footer_mobile_title}>Cose Capital</p>
//     <p className={styles.footer_mobile_email}>Email: info@brandlink.org</p>
//     <div className={styles.footer_mobile_links}>
//       <p className={styles.footer_mobile_link}>Home</p>
//       <p className={styles.footer_mobile_link} onClick={handleJoinWaitlist}>
//         Join Waitlist
//       </p>
//       <p className={styles.footer_mobile_link}>FAQ</p>
//     </div>
//     <div className={styles.footer_mobile_newsletter_layout}>
//       <p className={styles.footer_mobile_newsletter_title}>
//         BrandLink Newsletter
//       </p>
//       <div className={styles.footer_mobile_newsletter_email_input}>
//         <input
//           className={styles.mobile_email_input}
//           onChange={(event) => setEmail(event.target.value)}
//           placeholder="Email Address"
//         ></input>
//         <div className={styles.mobile_email_bt} /*onClick={handleSubmit}*/>
//           <p className={styles.mobile_email_bt_text}>Subscribe</p>
//         </div>
//       </div>
//     </div>
//     <p className={styles.footer_mobile_addy}>
//       1015 Mound Street Suite 315 53715 Madison, Wisconsin
//     </p>
//     <p className={styles.footer_mobile_copyright}>
//       © Copyright 2024 Cose Capital. All Rights Reserved.
//     </p>
//   </div>
// );
