import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";
import CareerComponent from "../../components/LandingComponents/CareersPage/CareerPage";

const CareerPage = () => {
  return (
    <div>
      <LandingNavbar
        childComponent={<CareerComponent />}
        activeText="Mission"
      />
    </div>
  );
};

export default CareerPage;
