import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";
import FAQComponents from "../../components/LandingComponents/FAQPage/faq_page";

const FAQPage = () => {
  return (
    <div>
      <LandingNavbar childComponent={<FAQComponents />} activeText="FAQ" />
    </div>
  );
};

export default FAQPage;
