import React from "react";
import { useState, useEffect } from "react";
import styles from "./home_top.module.css";
import { useNavigate } from "react-router";
import { useMediaQuery } from "@material-ui/core";

const HomePageTop = () => {
  const navigate = useNavigate();

  function handleApply() {
    navigate("/apply");
  }

  function handlePortfolio() {
    navigate("/portfolio");
  }

  const isDesktop = useMediaQuery("(min-width: 1024px)");

  return (
    <div className={styles.home_components}>
      <div className={styles.home_header_content}>
        <div className={styles.navbar_box}></div>
        <div className={styles.landing_header}>
          <div className={styles.main_copywriting}>
            <p className={styles.title_copywriting}>
              <FlickeringText text="architects of the future" />
            </p>
            <p className={styles.subtitle_copywriting}>
              <FlickeringText text="A different kind of venture capital. We don't predict the future. We build it." />
            </p>
          </div>
          <div className={styles.buttons}>
            <div className={styles.trial_button} onClick={handlePortfolio}>
              <p className={styles.trial_button_text}>Browse Portfolio</p>
            </div>
            {isDesktop && (
              <div className={styles.trial_button} onClick={handleApply}>
                <p className={styles.trial_button_text}>Apply</p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.landing_image}></div>
      <div className={`${styles.black_overlay} black_overlay`}></div>
    </div>
  );
};

const FlickeringText = ({ text }) => {
  const [flickeredText, setFlickeredText] = useState([]);

  useEffect(() => {
    const chars = text.split("").map((char, index) => ({
      char,
      style: {
        animationDuration: `${Math.random() * 0.4 + 0.1}s`,
        animationDelay: `${Math.random() * 2}s`,
      },
    }));
    setFlickeredText(chars);
  }, [text]);

  return (
    <div>
      {flickeredText.map((item, index) =>
        item.char === " " ? (
          <span key={index}>&nbsp;</span>
        ) : (
          <span key={index} className={styles.flickerLetter} style={item.style}>
            {item.char}
          </span>
        )
      )}
    </div>
  );
};

export default HomePageTop;
