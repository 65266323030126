import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";
import PortfolioComponent from "../../components/LandingComponents/PortfolioPage/PortfolioComponent";
import LandingFooter from "../../layout/LandingFooter/footer";

const PortfolioPage = () => {
  return (
    <div>
      <LandingNavbar
        childComponent={<PortfolioComponent />}
        activeText="Portfolio"
      />
    </div>
  );
};

export default PortfolioPage;
