import React, { useEffect } from "react";
import { useState } from "react";

import styles from "./career.module.css";

import LandingFooter from "../../../layout/LandingFooter/footer";

const CareerComponent = () => {
  const careerOptions = [
    {
      title: "Operational",
      description:
        "Oversee daily operations, ensuring efficiency and effectiveness in task management and execution. Drive big-picture strategies while attending to day-to-day details.",
      image: "/CareerIcons/operational-black.png",
    },
    {
      title: "Engineering",
      description:
        "Develop end-to-end solutions for a wide array of products aiming to combinine creativity with useful functionality. Work on interesting diverse projects in a dynamic environment.",
      image: "/CareerIcons/engineering-black.png",
    },
    {
      title: "Marketing",
      description:
        "Craft and implement marketing strategies to connect products with audiences. Drive sales through creative campaigns and forward thinking insightful market analysis.",
      image: "/CareerIcons/marketing-black.png",
    },
  ];

  return (
    <>
      <div className={styles.landing_page}>
        <div className={styles.page_header}>
          <div className={styles.page_titles}>
            <p className={styles.page_title}>Careers</p>
            <p className={styles.page_description}>
              Our mission is to empower founders, fueling their journey from
              ideation to impact. At the core of our philosophy lies the
              celebration of both innovation and invention—two forces that drive
              the advancement of industries and societies.
            </p>
          </div>
        </div>
        <div className={styles.page_line_break}></div>
        <div className={styles.career_options}>
          {careerOptions.map((option, index) => (
            <div key={index} className={styles.career_tile}>
              <img
                src={option.image}
                className={styles.career_image}
                alt={option.title}
              ></img>
              <p className={styles.career_title}>{option.title}</p>
              <p className={styles.career_description}>{option.description}</p>
              <div className={styles.cta_button}>Get in Touch</div>
            </div>
          ))}
        </div>
      </div>
      <LandingFooter />
    </>
  );
};

export default CareerComponent;
